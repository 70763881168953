import React from 'react'

export const NotFoundSVG = (props) => (
    <svg
    width={210}
    height={201}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={210}
      height={198}
    >
      <path fill="#fff" d="M0 0h210v198H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.955 98.978c0 54.663-44.139 98.977-98.587 98.977s-98.587-44.314-98.587-98.977C12.781 44.314 56.921 0 111.368 0c54.448 0 98.587 44.314 98.587 98.978 0 0 0 54.663 0 0Z"
        fill="#F5F7FF"
      />
      <path
        d="M38.344 45.226c-.134-.403.089-.852.491-.942l8.402-1.75c.447-.09.85.27.85.718l-1.342 20.146c-.044.762-1.072.897-1.34.224l-7.061-18.396ZM54.388 37.509c.045-.449.447-.718.894-.584l8.268 2.289c.447.134.626.628.402 1.032L53.54 57.564c-.357.629-1.34.315-1.251-.448l2.1-19.607ZM64.533 50.43c.224-.358.715-.448 1.028-.179l3.888 3.23a.72.72 0 0 1-.045 1.122l-9.742 6.82c-.626.449-1.385-.269-.983-.942l5.854-10.05Z"
        fill="#D9E0FF"
      />
      <path
        d="M89.023 100.189 9.608 123.52 1.385 95.164c-.715-2.468.67-5.025 3.129-5.743L72.13 69.544c4.067-1.166 8.313 1.167 9.52 5.25l7.373 25.395Z"
        fill="url(#b)"
      />
      <path
        d="m1.34 95.164.76 2.647c-.715-2.467.67-5.025 3.128-5.743L72.89 72.237c.178-.045.312-.09.447-.135 2.904-.673 5.005 1.167 5.63 3.455l2.593-.763c-1.207-4.083-5.453-6.416-9.52-5.204L4.47 89.42c.044 0 .044 0 0 0-2.458.718-3.844 3.275-3.129 5.743Z"
        fill="#3242A7"
      />
      <path
        d="M86.253 110.329a3.62 3.62 0 0 1-2.414 4.441l-69.806 20.505c-1.877.538-3.843-.539-4.424-2.423l-8.313-28.581a3.62 3.62 0 0 1 2.414-4.442l69.806-20.504c1.877-.538 3.888.538 4.424 2.423l8.313 28.581Z"
        fill="url(#c)"
      />
      <path
        d="M15.284 160.446a4.59 4.59 0 0 0 5.72 3.141l72.354-21.223c4.067-1.211 6.39-5.473 5.184-9.512L81.65 74.839l-2.592.763c.67 2.243-.09 4.935-2.905 5.967-.134.045-.313.09-.447.135L3.307 102.88a4.65 4.65 0 0 0-3.128 5.743l15.105 51.822Z"
        fill="#3242A7"
      />
      <path
        d="m94.117 144.967-72.353 21.267a4.59 4.59 0 0 1-5.72-3.141L.937 111.271c-.715-2.468.67-5.025 3.128-5.743L76.42 84.306c4.067-1.212 6.39-5.474 5.184-9.512l17.698 60.661c1.206 4.083-1.163 8.345-5.185 9.512Z"
        fill="url(#d)"
      />
      <path
        d="m24.088 139.179-13.81 4.038c-1.787.539-3.664-.493-4.2-2.288l-3.486-12.025 17.072-5.025c4.156-1.211 8.58 1.212 9.787 5.429 1.206 4.218-1.162 8.615-5.363 9.871Z"
        fill="#3242A7"
      />
      <path
        d="m1.474 125.091 1.43 4.935 3.263-.942-2.414-8.211a3.395 3.395 0 0 0-2.279 4.218Z"
        fill="#3242A7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.383 130.499c.438 2.589-1.298 5.042-3.878 5.479-2.58.436-5.026-1.309-5.464-3.899-.439-2.589 1.297-5.042 3.877-5.479 2.58-.436 5.027 1.309 5.465 3.899 0 0 .438 2.589 0 0Z"
        fill="#fff"
      />
      <path
        d="M184.214 72.147v-.404c-.179-7.044-5.944-10.589-5.944-10.589l-1.475 1.615-42.902-13.819a9.59 9.59 0 0 0-12.067 6.237L88.398 159.818l-3.71 4.038c.135 5.653 4.604 9.467 4.648 9.512 1.073 1.121 2.414 2.019 4.022 2.512l43.618 14.044a9.59 9.59 0 0 0 12.067-6.237l34.679-108.579c.313-.673.447-1.436.492-2.243v-.718Z"
        fill="#5D9FE7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154 47a8 8 0 1 1-16 0 8 8 0 0 1 16 0s0 4.418 0 0Z"
        fill="url(#e)"
      />
      <path
        d="M91.67 172.389c-5.05-1.615-7.822-7.044-6.213-12.114l34.68-108.58a9.589 9.589 0 0 1 12.066-6.236l43.618 14.043c5.05 1.616 7.821 7.045 6.212 12.115l-34.68 108.579a9.589 9.589 0 0 1-12.066 6.237l-43.618-14.044Z"
        fill="url(#f)"
      />
      <path
        d="m168.662 60.93-.581 1.75a3.129 3.129 0 0 1-3.888 2.019l-25.831-8.3a3.145 3.145 0 0 1-2.011-3.904l.581-1.75-5.676-1.84c-3.665-1.166-7.597.853-8.804 4.532L88.085 160.984c-1.162 3.68.85 7.628 4.514 8.839l43.037 13.864c3.664 1.167 7.597-.852 8.804-4.531l34.366-107.547c1.252-3.68-.804-7.628-4.469-8.84l-5.675-1.839Z"
        fill="#fff"
      />
      <path
        d="m162.092 124.148-66.633 13.999-3.754 11.71 65.874-11.62 4.513-14.089ZM102.476 116.162l65.65-10.993 9.698-30.33-64.757 8.21-10.591 33.113Z"
        fill="#EDEFFF"
      />
      <path
        d="M148.193 56.802c-.313-.09-.492-.449-.402-.763.089-.314.446-.493.759-.404l7.642 2.468c.313.09.492.449.403.763-.09.314-.447.493-.76.404l-7.642-2.468Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.796 59.898a.94.94 0 0 1-.939.942.94.94 0 0 1-.938-.942c0-.52.42-.942.938-.942a.94.94 0 0 1 .939.942s0 .52 0 0Z"
        fill="#fff"
      />
      <mask
        id="g"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={54}
        y={91}
        width={99}
        height={76}
      >
        <path fill="#fff" d="M54 91h99v76H54z" />
      </mask>
      <g mask="url(#g)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="m86.016 122.942-2.768-1.765-2.03.14L78.82 98.15l-2.768-1.764.23 2.043-5.49.557-14.024 28.413.462 2.553-2.353.65 2.768 1.764 15.777-1.625-1.2 6.871 2.769 1.765 7.98-.836-.922-8.728 4.613-.418-.646-6.453Zm-14.024-13 1.476 14.253-8.903.929 7.012-14.207.415-.975Z"
          fill="#F59187"
        />
        <path
          d="m83.248 121.141.646 6.453-4.567.464.922 8.728-7.98.836-.923-8.728-16.47 1.718-.876-5.014 14.024-28.367 8.073-.835 2.584 25.209 4.567-.464Zm-12.548 1.3-1.476-14.3-.416.975-7.012 14.253 8.904-.928Z"
          fill="#FFA9A0"
        />
        <path
          d="m152.954 129.813-2.076-2.786-.922 1.44-4.383-1.95-26.157 17.595-1.061 2.925-2.768-.928 2.076 2.785 15.131 6.732-5.628 5.246 2.076 2.786 7.335 3.25 3.552-8.032 4.198 1.857 2.63-5.943-2.076-2.785-1.753.139 9.826-22.331Zm-11.764 6.778-5.766 13.139-8.166-3.621 13.102-8.868.83-.65Z"
          fill="#F59187"
        />
        <path
          d="m144.881 152.061-2.63 5.942-4.198-1.857-3.552 8.032-7.335-3.25 3.552-8.032-15.131-6.731 1.707-4.782 26.157-17.596 7.427 3.296-10.195 23.121 4.198 1.857Zm-11.533-5.154 5.766-13.138-.83.65-13.102 8.867 8.166 3.621Z"
          fill="#FFA9A0"
        />
        <path
          d="m121.999 102.282-1.892-4.271-.369.882c-1.66-1.718-3.967-2.925-6.827-3.575-4.29-.975-7.889-.325-10.841 2.043-2.953 2.321-5.029 6.267-6.274 11.792l-1.661 7.196c-.692 3.25-.877 6.175-.554 8.682l-.83-.464 1.938 4.41c.046.14.138.279.184.418 1.615 3.389 4.567 5.571 8.857 6.546 4.337 1.022 7.981.325 10.888-2.089 2.906-2.368 4.982-6.314 6.228-11.792l1.66-7.289c1.108-4.921.969-9.053-.415-12.303-.046-.047-.046-.14-.092-.186Zm-12.917 25.952-5.398.836c-1.107-.603-1.845-1.532-2.214-2.786-.507-1.764-.415-4.271.323-7.521l2.214-9.656c.738-3.018 1.707-5.154 2.907-6.361 1.199-1.207 2.721-1.625 4.567-1.207 1.891.418 3.137 1.486 3.644 3.204.508 1.485.508 3.528.046 6.082l-6.089 17.409Z"
          fill="#F59187"
        />
        <path
          d="M112.957 130.101c-2.906 2.368-6.551 3.064-10.887 2.089-4.29-.975-7.243-3.157-8.857-6.546-1.615-3.389-1.846-7.707-.692-13.046l1.614-7.335c1.246-5.525 3.368-9.471 6.274-11.793 2.953-2.32 6.551-3.017 10.841-2.042 4.291.975 7.243 3.157 8.858 6.546 1.614 3.343 1.845 7.707.692 13.046l-1.615 7.289c-1.245 5.478-3.321 9.424-6.228 11.792Zm.508-29.063c-.554-1.718-1.753-2.786-3.645-3.203-1.845-.418-3.367-.047-4.567 1.207-1.199 1.207-2.168 3.342-2.906 6.36l-2.215 9.61c-.738 3.25-.83 5.757-.323 7.521.508 1.765 1.754 2.879 3.645 3.343 1.891.464 3.46 0 4.659-1.3 1.2-1.3 2.169-3.528 2.907-6.592l2.168-9.471c.738-3.297.83-5.804.277-7.475Z"
          fill="#FFA9A0"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.364 66.673c0-2.244.402-4.128 1.206-5.609.805-1.48 2.011-3.14 3.575-4.935 1.162-1.346 2.056-2.513 2.592-3.41.537-.897.805-1.93.805-3.051 0-1.211-.447-2.199-1.341-2.916-.894-.718-2.1-1.077-3.664-1.077-1.386 0-2.727.269-3.933.852-1.207.584-2.816 1.436-4.827 2.648-1.251.717-2.324 1.076-3.173 1.076-.938 0-1.698-.404-2.324-1.211-.625-.808-.938-1.795-.938-2.916 0-.808.134-1.481.447-2.02.268-.538.715-1.076 1.34-1.57 1.922-1.525 4.157-2.737 6.659-3.59 2.503-.852 5.05-1.3 7.598-1.3 2.726 0 5.139.493 7.329 1.435 2.19.987 3.843 2.289 5.05 3.993 1.207 1.706 1.788 3.59 1.788 5.699 0 1.615-.313 3.096-.939 4.442-.626 1.346-1.385 2.512-2.279 3.5-.894.986-2.056 2.198-3.531 3.678-1.653 1.526-2.905 2.827-3.754 3.859-.849 1.032-1.34 2.198-1.564 3.455-.179.852-.536 1.48-1.072 1.929-.537.449-1.207.673-2.012.673-.938 0-1.698-.314-2.234-.987s-.804-1.525-.804-2.647Zm-2.369 12.787c0-1.615.536-2.961 1.564-4.038 1.073-1.077 2.369-1.615 4.022-1.615 1.564 0 2.905.538 3.933 1.615 1.073 1.077 1.564 2.423 1.564 4.038 0 1.615-.536 2.961-1.564 4.038-1.073 1.077-2.369 1.615-3.933 1.615-1.653 0-2.994-.538-4.022-1.615-1.072-1.077-1.564-2.423-1.564-4.038Z"
        fill="url(#h)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127 193.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0s0 4.142 0 0Z"
      fill="url(#i)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 75a5 5 0 1 1-10 0 5 5 0 0 1 10 0s0 2.761 0 0Z"
      fill="url(#j)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 167a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9s0 4.971 0 0Z"
      fill="url(#k)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90 18.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0s0 3.59 0 0Z"
      fill="url(#l)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189 137a7 7 0 1 1-14 0 7 7 0 0 1 14 0s0 3.866 0 0Z"
      fill="url(#m)"
    />
    <defs>
      <linearGradient
        id="b"
        x1={-12.872}
        y1={69.28}
        x2={-12.827}
        y2={140.946}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C61E1" />
        <stop offset={1} stopColor="#3B4DB9" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={37.779}
        y1={91.303}
        x2={-2.223}
        y2={190.049}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6BD984" />
        <stop offset={1} stopColor="#6BCB80" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-15.088}
        y1={74.855}
        x2={-14.975}
        y2={195.84}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C61E1" />
        <stop offset={1} stopColor="#3B4DB9" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={138}
        y1={39}
        x2={138}
        y2={55}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={85}
        y1={45}
        x2={85}
        y2={186.892}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#89C1FF" />
        <stop offset={1} stopColor="#65ADFD" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={179.354}
        y1={25.827}
        x2={150.615}
        y2={62.536}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB5072" />
        <stop offset={1} stopColor="#F55978" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={112}
        y1={186}
        x2={112}
        y2={201}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="j"
        x1={6}
        y1={70}
        x2={6}
        y2={80}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="k"
        x1={28}
        y1={158}
        x2={28}
        y2={176}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="l"
        x1={77}
        y1={12}
        x2={77}
        y2={25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
      <linearGradient
        id="m"
        x1={175}
        y1={130}
        x2={175}
        y2={144}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E0FF" />
        <stop offset={1} stopColor="#D9E0FF" stopOpacity={0.01} />
      </linearGradient>
    </defs>
  </svg>
  );

export default NotFoundSVG;
